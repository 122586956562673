@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: GraublauWeb;
  src: url("../src/assets/OPTICopperplate.otf") format("opentype");
}

@font-face {
  font-family: GraublauWeb;
  font-weight: bold;
  src: url("../src/assets/OPTICopperplate.otf") format("opentype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 50px;
  text-align: left;
}

.App-header {
  height: 70px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.12);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 100;
  display: flex;
  align-items: center;
  z-index: 1232323;
}

.App-title {
}

.App-intro {
}
.contentNav {
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 70%;
  margin: 0 auto;
  position: relative;
  display: block;
}

.titleMdl {
  font-family: 'GFS Didot', serif;
  font-size: 1.5em;
  margin: 0 0 0 10px;
  align-self: center;
  color: rgb(166, 57, 35);
  text-transform: uppercase;
}

.hero__bg {
  width: 100vw !important;
  height: 780px;
  object-fit: cover !important;
  animation: hero-bg-anim 10s both !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  object-position: center;
  position: relative;
}

.hero__bg1 {
  width: 100vw !important;
  height: 880px;
  object-fit: cover !important;
  animation: hero-bg-anim 10s both !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
  position: relative;
}

.HeaderImg {
  position: relative;
  overflow: hidden;
  height: 70vh;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero__bg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.logo-format > p {
  margin: 0;
}

.logo-format {
  display: flex;
  color: #000000;
  align-items: center;
  justify-content: space-between;
}
@keyframes hero-bg-anim {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}


.btnRound {
  background: #fff;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  position: absolute;
  bottom: 10%;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: scroll-button-animation 2s infinite ease-in-out both;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow .2s ease;
}
@keyframes scroll-button-animation {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.imgArrow {
  height: 77px;
  margin-top: 13px;
}

.menuList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.menuList > a > p {
  margin: auto 10px;
  font-size: 13px;
  color: #484848;
}


.cool-link {
  display: inline-block;
  text-decoration: none;
  transition: all .5s;
  cursor: pointer;
}
h1 {
  font-family: GraublauWeb,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #484848;
}
.cool-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: rgb(166, 57, 35);
  transition: width .5s;
}

.cool-link:hover::after {
  width: 100%;
}

.cool-link:hover{
  color: rgb(166, 57, 35);
}

.AlainEtMimi {
  max-width: 500px;
  height: auto;
}

.TitleHome {
  margin-top: 40px;
  font-weight: 700;
  margin-bottom: 0px;
  font-family: GraublauWeb,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #484848;
  text-align: center;
}

.man-title {
  font-family: GraublauWeb,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 12px;
}
.CtnList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 45px 0 100px;
  flex-wrap: wrap;
}
.ctnImg {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}

.Card {
  width: 31%;
  background: rgba(222, 195, 113, 0.1);
  box-shadow: 0 4px 9px rgba(0,0,0,0.1);
  margin: 0px 5px 30px 5px;
}

.ctnImg > img {
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.CardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin:10px 20px 30px;
  text-align: left;
  position: relative;
  min-height: 80px;
}

.totality {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CardContent > a {
  position: absolute;
  bottom: 0;
}
.divEvent {
  text-align: left;
  margin-top: 30px;
}

.cool-btn {
  padding: 8px 25px;
  background: #FF5A5F;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  margin: 0 auto;
}

.margin-top {
  margin-top: 60px;
  text-decoration: underline;
  font-weight: bold;
}
.CardContent > p {
  margin: 0;
  word-wrap: break-word;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #484848;
  font-weight: 300;
  display: inline;
}

.nousContacter, .nousContacter > a {
  font-size: 12px;
  font-weight: normal;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #000;
  transition: all 0.5s;
}

.nousContacter > a:hover {
  color: #FF5A5F;
}

.btn-coolos {
  background:rgb(166, 57, 35);
  color: #fff;
  padding: 7px 15px;
  border: 1px solid transparent;
  border-radius: 3px;
  margin-top: 15px;
  transition: all 0.5s;
  cursor: pointer;
}

.CardContent button {
  background:rgb(166, 57, 35);
  color: #fff;
  padding: 7px 15px;
  border: 1px solid transparent;
  border-radius: 3px;
  margin-top: 15px;
  transition: all 0.5s;
  cursor: pointer;
}

.CardContent > a > button:hover {
  background: rgba(222, 195, 113, 0.7);
  color: #484848;
}

.ctnStars {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60% !important;
}
.Testimonials > h3 {
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0px;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #000;
  font-style: italic;
}
.Testimonials .LieuDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Testimonials > h2 {
  color: rgb(166, 57, 35);
  font-weight: normal;
  margin-bottom: 0px;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.2px;
  font-style: italic;
  align-self: center;
}

.Testimonials div > p {
  color: #000000;
  font-weight: normal;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
  font-style: italic;
  align-self: center;
  margin-bottom: 10px;
}

.App-footer {
  margin-top:40px;
  height: 80px;
  background: #fff;
  border-top: 1px solid #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ResaFooterCtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ResaFooterCtn p {
  font-size: 12px;
  font-weight: normal;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  line-height: 16px;
  letter-spacing: -0.2px;
}

footer {
  margin-top: 160px;
}

.Accroche {
  position: absolute;
  z-index: 1000;
  font-size: 80px;
  font-weight: 700;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  line-height: 90px;
  letter-spacing: -0.2px;
  color: #fff;
  text-align: left;
}

.ctnChambre {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.firstRow {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.secondRow {
  width: 300px;
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: fit-content;
  margin-top: 40px;
}

.ctnInfo1, .ctnInfo1 > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ctnInfo1 {
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: 50% 50%;
  grid-auto-columns: 50%;
  width: 100%;
}
.ctnInfo1 > div {
  margin-right: 15px;
}

.ctnInfo1 div p {
  font-size: 14px;
  font-weight: normal;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #000;
  text-align: left;
}

.firstRow span {
  font-size: 12px;
  font-weight: 700;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: rgb(166, 57, 35);
  text-align: left;
}

.CtnDesc {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  padding-top: 20px;
}

.CtnDesc p, .CtnDesc h2 {
  margin:20px 0 0;
}

.ctnInfo1 {
  padding-bottom: 30px;
  border-bottom: 1px solid #f5f5f5;
}
.CtnDesc h2 {
  font-size: 20px;
  font-weight: 700;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #484848;
  text-align: left;
}
.CtnDesc p, .CtnDesc div > ul > li {
  font-size: 14px;
  font-weight: normal;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: #000;
  text-align: left;
}

.btnAirbnb {
  color: #fff;
  background: #FF5A5F;
  padding: 7px 15px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  width: 150px;
  margin-bottom: 20px;
}

.btnAbritel {
  color: #fff;
  background: #0067db;
  padding: 7px 15px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  width: 150px;
  margin-bottom: 20px;
}

.btnBooking {
  color: #fff;
  background: #003580;
  padding: 7px 15px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  width: 150px;
}

.CtnbtnResa {
  display: flex;
  flex-direction: column;
}

.apartirde {
  font-weight: normal;
  margin: 0px;
  word-wrap: break-word;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #484848;
  text-align: left;
}

.apartirde > p {
  margin: 0;
}

.tarif {
  margin: 0px;
  word-wrap: break-word;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.2px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #484848;
  font-weight: 700;
}

.ctnBtnDays {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.ctnBtnDays button {
  border: 1px solid #f5f5f5;
  background-color: #fff;
  border-radius: 3px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.secondRow > div {
  margin: 30px;
}

.apartirde > p:first-child {
  margin: 10px 0 0;
}

.apartirde > p:last-child {
  margin: 0 0 10px;
}

.btnResa {
  text-align: left;
}

.fixedPrice {
  display: -webkit-box;
}

.CtnDesc h2 {
  font-size: 20px;
  font-weight: 700;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #484848;
  text-align: left;
}

.twiceCtn {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.twiceCtn > div {
  width: 44%;
}

.CtnDesc {
  width: 100%;
}

.ctnSlider {
  width: 90%;
  height: auto;
  position: relative;
  margin: 40px 0;
}

.gallery {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.divSlider {
  width: 100%;
  height: auto;
  position: relative;
  min-height: 600px;
  height: auto;
  max-height: 800px;
}

.ctnSlider .imgSlider {
  position: absolute;
  object-fit: contain;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  object-position: center;
}

.slick-prev:before, .slick-next:before {
  color: #484848;
}

.mapGoogle {
  margin-top: 100px;
  width: 100%;
}

.mapGoogle h2 {
  margin-bottom: 30px;
}
.ctnInfo1 div img {
  margin-right: 5px;
}

.ctnSlider .imgSlider {
  max-height: 600px;
}

@media (max-width: 1320px){
  .ctnInfo1 div p {
    font-size: 11px;
  }
  .ctnInfo1 div img {
    width: 20px;
  }
  .ctnInfo1 div:nth-child(2) img {
    width: 30px;
  }
  .secondRow {
    width: 270px;
  }
  .CtnDesc p, .CtnDesc div > ul > li {
    max-width: 90%;
    font-size: 12px;
  }
  .CtnDesc h2 {
    font-size: 18px;
  }
  .mapGoogle {
    width: 90%;
  }
  .HeaderImg {
    height: 70vh;
    min-height: 400px;
  }
  .Accroche {
    font-size: 57px;
    line-height: 65px;
  }
  #Home .mapGoogle {
    width: 100% !important;
  }
  .ctnSlider .imgSlider {
    max-height: 400px;
  }
  .divSlider {
    width: 100%;
    height: auto;
    position: relative;
    min-height: 400px;
    height: auto;
    max-height: 800px;
  }
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  position: absolute;
  right: 7px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
.hamburger:hover {
  opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 34px;
  height: 4px;
  background-color: #484848;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }
.hamburger-inner::before {
  top: -10px; }
.hamburger-inner::after {
  bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg); }
.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg); }
.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg); }
.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg) rotateY(180deg); }
.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }
.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }
.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }
.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease; }
.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0; }
.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease; }
.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0; }
.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent; }
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent; }
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; }
.hamburger--slider .hamburger-inner::after {
  top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0; }
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; }
.hamburger--slider-r .hamburger-inner::after {
  top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0; }
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent; }
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg); }
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear; }
.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity; }
.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s; }
.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }
.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear; }
.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity; }
.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s; }
.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }
.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg); }


@media (max-width: 450px) {
  .navMobile {
    position: absolute;
    height: 100vh;
    background: #fff;
    width: 100vw;
    left: 0;
    right: 0;
    top: 69px;
    transition: all 0.5s;
    opacity: 0;
    animation: hero-anim 0.5s both !important;
  }
  .contentNav {
    height:100%;
    width: 90%;
    align-items: center;
  }
  @keyframes hero-anim {
    0% {
      opacity:0;
    }
    100% {
      opacity: 1;
    }
  }
  .hero__bg {
    animation: none;
  }
  .menuList {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 129px;
  }
  .menuList .ResaFooterCtn {
    margin-top: 100px;
  }
  .menuList .ResaFooterCtn > p {
    font-size: 16px;
    border-bottom: 1px solid #484848;
  }
  .menuList > a > p {
    margin: 12px auto;
    font-size: 28px;
    color: #484848;
  }
  .Accroche {
    font-size: 32px;
    line-height: 38px;
  }
  .CtnList {
    flex-direction: column;
    margin: 15px 0 30px;
  }
  .Testimonials {
    width: 100% !important;
  }
  .TitleHome {
    text-align: center;
  }
  .mapGoogle {
    width: 100%;
  }
  footer {
    margin-top: 41px;
  }
  .ResaFooterCtn > img, .ResaFooterCtn #para {
    display: none;
  }
  .App-footer {
    flex-direction: column;
    justify-content: center;
  }
  .ctnChambre {
    flex-direction: column-reverse;
  }
  iframe {
    height: 350px;
  }
  .Card {
    width: 100%;
    margin: 20px 0;
    text-decoration: none;
  }
  .firstRow {
    width: 100%;
  }
  .content {
    width:80%;
  }
  .ctnInfo1 > div {
    flex-direction: column;
  }
  .ctnInfo1 div p {
    line-height: 14px;
    font-size: 10px;
  }
  .ctnSlider .imgSlider, .divSlider {
    max-height: 250px;
  }
  .slick-slider {
    height: 290px;
  }
  .slick-dots li {
    margin:0;
  }
  .secondRow {
    width: 100%;
  }
}


a {
  text-decoration: none;
}
